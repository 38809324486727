<template>
  <div class="notification">
    <div class="text-center mt-xs-4 mt-0">
      <el-badge v-if="unread.length > 0" is-dot class="item">
        <div class="notification-icon"> <i class="el-icon-bell" /> </div>
      </el-badge>
      <div v-else class="notification-icon"> <i class="el-icon-bell" /> </div>
      <h6 v-if="unread.length > 0" class="bold">You have {{ unread.length }} unread messages</h6>
      <h6 v-else class="bold">This is where your message notifications appear.</h6>
    </div>
    <el-card class="messages">
      <router-link v-for="conversation in unread" :key="conversation.conversation_id" style="cursor: pointer" :to="`/conversations/${conversation.conversation_id}`">
        <el-row :gutter="10" class="message-row">
          <el-col :span="4">
            <img :src="profilePhotoUrl(conversation.last_message.participant.member)" alt="..." class="rounded-circle w-100">
          </el-col>
          <el-col :span="20">
            <h6>{{ conversation.last_message.participant.member.name }}</h6>
            <p class="small">{{ conversation.last_message.message }}</p>
          </el-col>
        </el-row>
      </router-link>
    </el-card>
    <div v-if="userType == 'employer'">
      <center>
        <el-button v-if="unread.length > 0" type="success mb-3 mt-3" @click="goToConversations">View all messages</el-button>
        <router-link to="/conversations" v-else><el-button type="success">Start Messaging</el-button></router-link>
      </center>
    </div>
    <div v-else>
      <center>
        <el-button v-if="unread.length > 0" type="success mb-3 mt-3" @click="goToConversations">View all messages</el-button>
      </center>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Conversation from '~/models/Conversation'
import showsProfilePhoto from '~/mixins/showsProfilePhoto'
import getUserType from '~/helpers/getUserType'

export default {
  mixins: [showsProfilePhoto],

  computed: {
    ...mapGetters('messenger', ['unread']),

    userType () {
      return getUserType()
    }
  },

  methods: {
    goToConversations () {
      Conversation.visit()
    },

    readMessage (conversation) {
      Conversation.visit(conversation.conversation_id)
    },
  },
}
</script>
<style>
    .notification-icon {
        margin-top: -8px;
        margin-right: -3px;
    }
    .notification h6{
      line-height: 1.6;
    }
</style>
