<template>
  <dashboard>
    <el-row :gutter="15">
      <el-col :xs="24" :md="16">
        <!-- only show for free users -->
        <el-row v-if="isOnFreePlan" :gutter="15" class="mb-3">
          <el-col :span="24">
            <el-card class="admin-message">
              <img src="/images/welcome.png" alt="" class="w-75 mb-3 mx-auto d-block">
              <!-- <h5 class="bold">
                <i class="el-icon-message mr-2" /> Welcome to Citronworks!
              </h5> -->
              <p>You are on the Free plan, which lets you try CitronWorks and post a test job. You can also search our freelancer database but you can not contact the freelancers. </p>
              <p>To contact the freelancers you need to upgrade to a paid plan.
                <!-- , we are offering <span class="bold">75% off</span> to the next 500 employers that upgrade to the paid plan. -->
              </p>
              <center>
                <router-link to="/employer/freelancers">
                  <el-button type="success" class="mr-2">Search for freelancers</el-button>
                </router-link>
                <router-link to="/employer/billing">
                  <el-button type="warning" class="mt-1">Upgrade</el-button>
                </router-link>
              </center>
            </el-card>
          </el-col>
        </el-row>
        <!-- only show for paid users -->
        <el-row v-if="! isOnFreePlan && showingThankYou" :gutter="15" class="mb-3">
          <el-col :span="24">
            <el-card class="admin-message">
              <h5 class="mb-3">
                <!-- <span class="bold"><i class="el-icon-message mr-2" /> Welcome to Citronworks!</span> -->
                <span class="small float-right cursor-pointer" @click="dismissWelcome">Close</span>
              </h5>
              <img src="/images/welcome.png" alt="" class="w-75 mb-3 mx-auto d-block">
              <p>Thanks for upgrading, you are now able to contact all of our freelancers, and they can apply for jobs you post.</p>
              <center>
                <router-link to="/employer/freelancers">
                  <el-button type="success" class="mr-2">Search for freelancers</el-button>
                </router-link>
                <router-link to="/employer/jobs/create"><el-button type="warning" class="mt-1">Add job post</el-button></router-link>
              </center>
            </el-card>
          </el-col>
        </el-row>
        <div class="">
          <el-row :gutter="15">
            <el-col :span="24">
              <el-card class="employer-job-feed jobfeed feed mb-3">
                <div class="header">
                  <h5>
                    <i class="el-icon-s-order" /> Your Job Posts
                  </h5>
                </div>
                <employer-job-tabs :per-page="5" />
              </el-card>
            </el-col>
          </el-row>
        </div>
        <!-- <div class="freelancer-profile">
          <el-row :gutter="15">
            <el-col :span="18">
              <h5 class="mt-4 mb-0 bold">
                <i class="el-icon-user-solid" /> Profiles you might be
                interested in
              </h5>
            </el-col>
            <el-col :span="6" class="text-right">
              <router-link tag="p" to="/employer/freelancers" class="mt-4 small pointer">View Profiles</router-link>
            </el-col>
          </el-row>
          <hr>
          <el-card>
            <div class="text-center">
              <p class="small text-center">You have not searched for any freelancer profiles</p>
              <router-link to="/employer/freelancers"><el-button type="success" class="">Start your search now</el-button></router-link>
            </div>
          </el-card> -->
          <!--
          Show when there is already an algo for freelancer profiles related to their searches
          <el-card v-for="freelancer in freelancers" :key="freelancer.freelancer_id">
            <el-row :gutter="15" type="flex">
              <el-col :span="3">
                <img :src="profilePhotoUrl(freelancer)" class="rounded-circle w-100">
              </el-col>
              <el-col :span="18" class="align-self-center">
                <p class="bold small mb-1" :class="{ blur: freelancer.blurred }">{{ freelancer.first_name }} {{ freelancer.last_name }}</p>
                <p class="small mb-2 mt-1">
                  <span v-if="freelancer.profile">{{ freelancer.profile.intro }} </span></p>
                <p class="small mb-2"><span class="bold gray-text">Skills:</span></p>
                <el-tag v-for="skill in freelancer.skill" :key="skill.skill_id" type="info" class="mt-1 mr-1" size="mini">
                  {{ skill.skill_name }}
                </el-tag>
              </el-col>
              <el-col v-if="! freelancer.blurred" :span="3" class="text-center text-lg-right">
                <el-button type="warning" size="small" class="" plain circle icon="el-icon-message"
                           @click="sendMessage(freelancer)"
                />
              </el-col>
            </el-row>
          </el-card>-->
        <!-- </div> -->
      </el-col>
      <el-col :xs="24" :md="8">
        <notifications />
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import moment from 'moment'
import Dashboard from '~/components/Dashboard/Dashboard'
import Notifications from '~/components/Messenger/Notifications'
import showsProfilePhoto from '~/mixins/showsProfilePhoto'
import EmployerJobTabs from '~/components/Jobs/Employer/Tabs'
import checksIfOnFreePlan from '~/mixins/checksIfOnFreePlan'
// import Conversation from '~/models/Conversation'
// import Freelancer from '~/models/Freelancer'

export default {
  components: {
    Dashboard,
    Notifications,
    EmployerJobTabs,
  },

  mixins: [showsProfilePhoto, checksIfOnFreePlan],

  data () {
    return {
      showingThankYou: false,
      // freelancers: [],
      // value: 4.5,
    }
  },

  computed: {
    month () {
      return moment().format('MMMM')
    },
  },

  created () {
    if (this.getCookie('show_welcome')) {
      this.showingThankYou = true;
    }
  },

  // created () {
  //   // Freelancer.search()
  //   //   .then(response => {
  //   //     this.freelancers = response.data.data
  //   //   })
  // },

  methods: {
    dismissWelcome () {
      this.showingThankYou = false
      // Deletes show_welcome cookie
      document.cookie =
              "show_welcome=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },

    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    // sendMessage (freelancer) {
    //   Conversation.createAndRedirect(freelancer.freelancer_id, 'freelancer')
    // },
  },
}
</script>

<style scoped>
.admin .body {
  padding: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>

